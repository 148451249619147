html{
  height: 100%;
  padding: 0;
  margin: 0;
  transition: ease-in-out 0.3s;
  scroll-behavior: smooth;
 
}

::-webkit-scrollbar {
  display: none;
}

.App {
  display: flex;
  font-family: 'Play', sans-serif;
  flex-direction: column;
  height: auto;
  transition: ease-in-out 0.3s;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./17580.jpg);
}

header {
  margin: 0 auto;
  width: 100%;
}

main {
  margin-top: 80px;
  margin-bottom: 50px;
}

a {
  text-decoration: none;
  cursor: pointer;
  width: none;
  font-family: 'Times New Roman', Times, serif;
}



button {
  cursor: pointer;
}